// WIDTH
@sidebar-width: 250px;

@modal-small-width: 360px;
@modal-medium-width: 500px;
@modal-large-width: 650px;
@modal-m-large-width: 850px;
@modal-x-large-width: 1200px;
@modal-xx-large-width: 2000px;

// HEIGHT
@page-header-height: 100px;
@default-row-height: 50px;
@input-height-base: 38px;
@header-height: @default-row-height;

// PADDING
@spacing-x1: 4px;
@spacing-x2: 8px;
@spacing-x3: 12px;
@spacing-x4: 16px;
@spacing-x5: 20px;
@spacing-x6: 24px;
@spacing-x7: 28px;
@spacing-x8: 32px;

// FONT
@main-font-family: "Source Sans Pro", sans-serif;
@title-font-family: "Arimo", sans-serif;
@numbers-font-family: "Roboto", sans-serif;
@default-font-size: 14px;
@default-font-size-small: 12px;
@default-bold-value: 600;

// COLOR
@black-base: #000;
@white-base: #fff;
@body-background-color: #f5f5f5;

@main-header-background-color: #373737;

@main-font-color: #666666;
@secondary-font-color: #b1afaf;
@a-bit-darker-secondary-font-color: #a6a6a6;

@main-border-color: #dddddd;
@secondary-border-color: #e6e6e6;
@divider-border-color: #e6e6e6a6;

@header-hover-background-color: #444444;
@item-hover-background-color: #f8f8f8;
@item-hover-background-color-light: #fdfdfd;

@green-color: #7b981f;
@green-color-light: #f7fbea;
@yellow-color: #c3d010;
@blue-color: #2196f3;
@blue-color-light: #6db9f8;
@orange-color: #ffae00;
@red-color: #d54334;
@red-color-light: #fbebea;

@main-icon-color: #bfbfbf;

@apirix-light: #ebefae;

// BORDER
@default-border: 1px solid @main-border-color;
@border-radius-base: 4px;
@border-radius-round: 50%;

// SCREEN
@screen-supersmall-extra-max-ultra: 340px;
@screen-supersmall-extra-between-max-and-ultra: 410px;
@screen-supersmall-extra-max: 475px;
@screen-supersmall-max: 550px;
@screen-sm-min: 768px;
@screen-md-min: 992px;
@screen-lg-min: 1200px;
@screen-lgx2-min: 1300px;
@screen-lg-large-min: 1420px;
@screen-lg-largex2-min: 1500px;
@screen-lg-largex3-min: 1600px;
@screen-lg-largex4-min: 1800px;
@screen-lg-largex5-min: 2000px;

@screen-xs-max: (@screen-sm-min - 1);
@screen-sm-max: (@screen-md-min - 1);
@screen-md-max: (@screen-lg-min - 1);
@screen-md-lg-max: (@screen-lgx2-min - 1);
@screen-lg-max: (@screen-lg-large-min - 1);
@screen-lg-large-max: (@screen-lg-largex3-min - 1);

// SCROLLBAR

@scrollbar-height: 6px;
@scrollbar-width: 6px;

@scrollbar-thumb-radius: 4px;
@scrollbar-thumb-width: 6px;
@scrollbar-thumb-padding: 2px 0;
@scrollbar-thumb-margin: 2px 0;
@scrollbar-thumb-color: @main-font-color;
@scrollbar-thumb-transition: background-color 2s ease-in-out;

@scrollbar-track-margin: 0;
@scrollbar-track-radius: 4px;
@scrollbar-track-color: @secondary-border-color;

// BOX SHADOW
@default-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

// TRANSITION
@standard-transition: 0.2s all ease-in-out;
@fast-transition: 0.1s all ease-in-out;
@slow-to-fast-transition: 0.15s cubic-bezier(0.86, 0, 0.07, 1);
@refresh-spinner: circle-loader-load 0.95s infinite
  cubic-bezier(0.43, 0.84, 0.61, 0.14);
@straighten-up: straighten-up 0.15s 1 ease-in-out;

// GRADIENT
@border-linnear-gradient: linear-gradient(
  to right,
  rgba(255, 255, 255, 0),
  rgba(0, 0, 0, 0.1) 40%,
  rgba(0, 0, 0, 0.1) 60%,
  rgba(255, 255, 255, 0)
);
@vertical-border-linnear-gradient: linear-gradient(
  to bottom,
  rgba(255, 255, 255, 0),
  rgba(0, 0, 0, 0.1) 40%,
  rgba(0, 0, 0, 0.1) 60%,
  rgba(255, 255, 255, 0)
);
