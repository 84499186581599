.u-uppercase {
	text-transform: uppercase !important;
}

.u-bold {
	font-weight: 600 !important;
}

.u-italic {
	font-style: italic !important;
}

.u-cursor-text {
	cursor: text !important;
}

.u-cursor-default {
	cursor: default !important;
}

.u-cursor-pointer {
	cursor: pointer !important;
}

.u-display-none {
	display: none !important;
}

.u-rounded {
	border-radius: 50% !important;
}