@import './variables.less';

@font-face {
	font-family: inspection-icons;
	src: url(/assets/images/icons/font/InspectionIcons.ttf);
}

[class^="bi-"], [class*="bi-"], [class^="ii-"], [class*="ii-"] {
	color: @main-icon-color;
}

[class^="bi-"]::before, [class*="bi-"]::before{
	color: currentColor;
}

[class^="ii-"]::before, [class*="ii-"]::before {
	content: var(--icon-data);
	display: inline-block;
	font-family: inspection-icons !important;
	font-style: normal;
	font-weight: normal !important;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	vertical-align: -.125em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: currentColor;
}

.ii-task {
	--icon-data: "\00E800";
}
.ii-entities {
	--icon-data: "\00E801";
}
.ii-images {
	--icon-data: "\00E802";
}
.ii-sort-up {
	--icon-data: "\00E803";
}
.ii-location-lock {
	--icon-data: "\00E804";
}
.ii-assignment {
	--icon-data: "\00E805";
}
.ii-image-loading {
	--icon-data: "\00E806";
}
.ii-erase {
	--icon-data: "\00E807";
}
.ii-filter {
	--icon-data: "\00E808";
}
.ii-link {
	--icon-data: "\00E809";
}
.ii-delete {
	--icon-data: "\00E80a";
}
.ii-measure {
	--icon-data: "\00E80b";
}
.ii-info {
	--icon-data: "\00E80c";
}
.ii-facility {
	--icon-data: "\00E80d";
}
.ii-location {
	--icon-data: "\00E80f";
}
.ii-sort-down {
	--icon-data: "\00E810";
}
.ii-search {
	--icon-data: "\00E811";
}
.ii-flask-1 {
	--icon-data: "\00E812";
}
.ii-camera-add {
	--icon-data: "\00E814";
}
.ii-archive {
	--icon-data: "\00E815";
}
.ii-flask {
	--icon-data: "\00F0c3";
}
.ii-template-type {
	--icon-data: "\00E80E";
}