@import "./../../assets/styles/variables";
@import "./../../assets/styles/media";

*,
*:before,
*:after {
  box-sizing: border-box;
}

*:focus-visible {
  outline: none;
}

html {
  overflow: hidden;

  body {
    font-family: @main-font-family;
    font-size: @default-font-size;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: auto;
    color: @main-font-color;
  }
}

a {
  text-decoration: none;
  color: @main-font-color;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

b,
strong {
  font-weight: @default-bold-value;
}

em {
  font-style: italic;
}

input {
  display: block;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  height: @input-height-base;
  padding: 0 @spacing-x3;
  border: 1px solid @secondary-border-color;
  border-radius: @border-radius-base;
  font-family: @main-font-family;
  font-size: @default-font-size;
  color: @main-font-color;

  &:hover {
    border-color: @secondary-font-color;
  }

  &:focus {
    border-color: @green-color;
  }

  &.has-errors {
    border-color: @red-color;
  }

  &::placeholder {
    color: @secondary-font-color;
  }

  &::-webkit-calendar-picker-indicator {
    display: none;
  }
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  display: block;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  resize: none;
  height: @input-height-base * 2;
  padding: 8px @spacing-x3;
  border: 1px solid @secondary-border-color;
  border-radius: @border-radius-base;
  font-family: @main-font-family;
  font-size: @default-font-size;
  color: @main-font-color;

  &:hover {
    border-color: @secondary-font-color;
  }

  &:focus {
    border-color: @green-color;
  }

  &.has-errors {
    border-color: @red-color;
  }

  &::placeholder {
    color: @secondary-font-color;
  }
}

button {
  -webkit-appearance: none;
  appearance: none;
  height: @input-height-base;
  padding: 0 @spacing-x3;
  border: 1px solid @secondary-border-color;
  border-radius: @border-radius-base;
  font-family: @main-font-family;
  font-size: @default-font-size-small;
  color: @main-font-color;
  text-transform: uppercase;
  font-weight: @default-bold-value;
  cursor: pointer;
  white-space: nowrap;

  &.btn-success {
    background-color: @green-color;
    color: @white-base;
    border-color: @green-color;

    &:hover,
    &:focus {
      background-color: darken(@green-color, 5%);
    }

    &::before {
      color: @white-base !important;
    }
  }

  &.btn-danger {
    background-color: @red-color;
    color: @white-base;
    border-color: @red-color;

    &:hover,
    &:focus {
      background-color: darken(@red-color, 5%);
    }

    &::before {
      color: @white-base !important;
    }
  }

  &.btn-info {
    background-color: @blue-color;
    color: @white-base;
    border-color: @blue-color;

    &:hover,
    &:focus {
      background-color: darken(@blue-color, 5%);
    }

    &::before {
      color: @white-base !important;
    }
  }

  &.btn-transparent {
    background-color: @white-base;

    &:hover,
    &:focus {
      background-color: darken(@white-base, 5%);
    }
  }

  &:disabled {
    .disabled;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.dummy-text {
  color: @secondary-font-color;
}

.blurableContainer {
  &::after {
    content: "";
    width: 100%;
    height: 100%;

    pointer-events: none;

    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;

    opacity: 0;
    backdrop-filter: blur(8px);
    transition: opacity 200ms;
  }

  &.blurred {
    &::after {
      pointer-events: unset;
      opacity: 1;
    }
  }
}

.app-container {
  .blurableContainer;
}

.loadmask {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;
    background: rgba(255, 255, 255, 0.4);
  }
}

.p-popover {
  background-color: @white-base !important;
  box-shadow: @default-box-shadow !important;
  z-index: 10 !important;
  border-radius: @border-radius-base !important;
  border: @default-border !important;
  max-width: 240px !important;

  &:before {
    content: none !important;
  }

  &:after {
    content: none !important;
  }
}

.popover-panel-header {
  height: @input-height-base;
  background-color: @main-font-color;
  color: @white-base;
  font-size: @default-font-size-small;
  padding: 10px @spacing-x3;
  border-top-left-radius: @border-radius-base;
  border-top-right-radius: @border-radius-base;
  font-weight: @default-bold-value;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.p-component-overlay {
  position: absolute;
  top: 0;
  width: 100vw;
  height: var(--doc-height);
  z-index: 1;
}

// Simple table menu popovers.
.standard-menu-item {
  height: @input-height-base;
  display: flex;
  align-items: center;
  padding: 0 @spacing-x3;
  cursor: pointer;
  gap: @spacing-x2;

  i {
    color: @main-font-color;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:hover {
    background: @green-color;
    color: @white-base;

    a {
      color: @white-base;
      text-decoration: none;
    }

    i {
      color: @white-base;

      &.default {
        display: none !important;
      }

      &.hover {
        display: inline-block !important;
      }
    }
  }

  &.red-highlight {
    &:hover {
      background: @red-color;
    }
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;

    .check-icon {
      display: inline-block;

      i {
        margin-right: 2px;
        margin-left: 14px;
        font-size: 17px;

        &.default {
          display: inline-block;

          &.bi-check-square-fill {
            color: @green-color;
          }
        }

        &.hover {
          display: none;
        }
      }
    }
  }

  &.sort {
    height: @input-height-base;
    font-size: @default-font-size-small;
    font-weight: @default-bold-value;
    text-transform: uppercase;

    .icon {
      color: @main-font-color;
      font-size: 18px;
    }

    &.highlighted,
    &:hover {
      background-color: @green-color;
      color: @white-base;

      .icon {
        color: @white-base;
      }
    }
  }
}

// Notification messages popover.
.notification-messages-menu-item {
  cursor: pointer;
  position: relative;

  .notification-message-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon-title-wrapper {
      display: flex;
      align-items: center;
      overflow: hidden;

      .notification-message-icon {
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        flex: none;

        &.release {
          color: @blue-color;
        }

        &.bug {
          color: @red-color;
        }

        &.info {
          color: @blue-color;
        }
      }

      .notification-message-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: @default-font-size-small;
        padding-right: 16px;
        font-weight: @default-bold-value;
      }
    }

    .date {
      font-size: 11px;
      color: @secondary-font-color;
      align-self: end;
      padding-right: @spacing-x3;
      white-space: nowrap;
    }
  }

  &.opened {
    opacity: 0.8;

    .notification-message-wrapper {
      .icon-title-wrapper {
        .notification-message-icon {
          color: @secondary-font-color;
        }

        .notification-message-title {
          font-weight: initial;
        }
      }
    }
  }

  &:not(.dummy) {
    &:hover {
      box-shadow: @default-box-shadow;
      z-index: 1;
      border-bottom-color: transparent;
    }
  }

  &.dummy {
    cursor: default;

    .notification-message-wrapper {
      opacity: 0.6;

      .icon-title-wrapper {
        .notification-message-icon {
          color: @main-font-color;
        }

        .notification-message-title {
          padding-right: 16px;
          font-weight: initial;
        }
      }
    }
  }

  // förstora heighten isf?
  &:not(:last-of-type) {
    border-bottom: 1px solid @secondary-border-color;
  }
}

// Notice icon popover.
.ni-menu-item {
  display: flex;
  align-items: center;
  padding: @spacing-x3 @spacing-x4;
  border-radius: @border-radius-base;
}

.apx-scrollbar {
  // Firefox.
  @supports (-moz-appearance: meter) {
    scrollbar-width: thin;

    &.firefox-no-anchor {
      overflow-anchor: none;
    }
  }

  &::-webkit-scrollbar {
    width: @scrollbar-width;
    height: @scrollbar-height;
  }

  &::-webkit-scrollbar-track {
    background: @scrollbar-track-color;
    margin: @scrollbar-track-margin;
    border-radius: @scrollbar-track-radius;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @scrollbar-thumb-color;
    transition: @scrollbar-thumb-transition;
    border-radius: @scrollbar-thumb-radius;
  }

  ::-webkit-scrollbar-thumb {
    background-color: @scrollbar-thumb-color;

    &:focus {
      background-color: darken(@scrollbar-thumb-color, 10%);
    }
  }
}

.scroll-shadow {
  background:
		/* Shadow Cover TOP */
    linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top,
    /* Shadow Cover BOTTOM */ linear-gradient(rgba(255, 255, 255, 0), white 70%)
      center bottom,
    /* Shadow TOP */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center top,
    /* Shadow BOTTOM */
      radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center bottom;

  background-repeat: no-repeat;
  background-size:
    100% 40px,
    100% 40px,
    100% 14px,
    100% 14px;
  background-attachment: local, local, scroll, scroll;
}

.swal2-popup {
  width: 360px !important;
  padding: @spacing-x4 !important;
}

.swal2-icon {
  margin-top: 0 !important;
}

.swal2-content {
  padding: 0 !important;
  font-size: 16px !important;
}

.swal2-title {
  font-size: 24px !important;
}

.swal2-actions {
  font-size: 14px !important;

  &:not(.single-button) {
    margin: 30px 0 0 0 !important;
    justify-content: space-between !important;
    gap: @spacing-x3;
  }
}

.swal2-checkbox {
  cursor: pointer;

  #swal2-checkbox {
    flex-shrink: 1;
    width: 20px;
    appearance: auto !important;
    -webkit-appearance: auto !important;
  }

  .swal2-label {
    white-space: nowrap;
  }
}

.wider-swal {
  width: 600px !important;
}

// PRIMENG TABS

.p-tabs {
  background-color: @white-base !important;

  .p-tablist {
    border-bottom: 1px solid @main-border-color !important;
    background-color: @white-base !important;
    align-items: center !important;
    height: 50px !important;
    flex: none !important;

    .p-tablist-content {
      .p-tablist-tab-list {
        border: none !important;

        .p-tab {
          border: none !important;

          .heading-wrapper {
            display: flex;
            align-items: center;
            gap: @spacing-x3;
            padding: 0 @spacing-x4;
            height: @default-row-height;

            .icon {
              display: flex;
              align-items: center;
              justify-content: center;
              color: @main-font-color;
            }
          }

          &:hover {
            .heading-wrapper {
              .icon,
              .title {
                color: @green-color;
              }
            }
          }

          &.p-tab-active {
            cursor: default;

            .heading-wrapper {
              .icon,
              .title {
                color: @green-color;
              }

              .title {
                font-weight: @default-bold-value;
              }
            }
          }
        }

        .p-tablist-active-bar {
          background-color: @green-color !important;
          height: 2px !important;
          bottom: 1px !important;
        }
      }
    }

    .p-tablist-nav-button {
      width: @input-height-base;
      height: @input-height-base;
      position: initial !important;
      margin: 0 @spacing-x2 !important;
    }
  }

  .p-tabpanels {
    height: 100% !important;
  }
}

// PRIMENG DRAWER (TODO lägg alla dessa i separat less fil??)

.p-drawer {
  background-color: @white-base !important;
  box-shadow: @default-box-shadow !important;
  z-index: 9 !important;
}

.p-drawer-left {
  width: 33% !important;
  min-width: 250px !important;
  max-width: 500px !important;
}

.p-drawer-bottom {
  height: 50% !important;
}

.p-drawer-header {
  height: 50px !important;
  padding: 0 @spacing-x4 !important;
  border-bottom: 1px solid @main-border-color !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  gap: @spacing-x4 !important;

  .label {
    font-family: @title-font-family;
    text-transform: uppercase;
    color: @green-color;

    .secondary-label {
      font-size: @default-font-size-small;
      text-transform: none;
      font-family: @main-font-family;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: @spacing-x2;
    margin-left: auto;

    .action {
      width: @input-height-base;
      height: @input-height-base;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: @border-radius-base;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: @main-font-color;
      }

      &:hover {
        background-color: @item-hover-background-color;
      }
    }
  }
}

.p-drawer-content {
  .apx-scrollbar;
  .scroll-shadow;
}

.p-drawer-mask {
  z-index: 8 !important;
}

// PRIMENG COLORPICKER

.p-colorpicker {
  display: block !important;
}

.p-colorpicker-hue {
  border-radius: @border-radius-base;
  border: 1px solid black;
}

.p-colorpicker-panel {
  background-color: transparent;
  height: 162px;

  .p-colorpicker-color-background {
    border-radius: @border-radius-base;
  }
}

.p-colorpicker-color-handle {
  border: 1px solid @white-base !important;
  width: 16px !important;
  height: 16px !important;
  margin-left: -8px !important;
  margin-top: -8px !important;
}

.p-colorpicker-hue-handle {
  border: 1px solid black;
  background-color: black;
  border-radius: @border-radius-base;
  margin-left: -3px;
  margin-top: -3px;
}

.p-colorpicker-color-selector,
.p-colorpicker-color {
  border-radius: @border-radius-base;
}

.p-colorpicker-color-selector {
  border: 1px solid black;
}

// PRIMENG DATEPICKER

.p-datepicker {
  width: 100%;

  .p-datepicker-input {
    border: 1px solid @secondary-border-color !important;
    border-radius: @border-radius-base !important;
    padding: 0 @spacing-x3 !important;
    color: @main-font-color !important;
    font-size: @default-font-size !important;
    transition: none !important;

    &:hover {
      border-color: @secondary-font-color !important;
    }

    &:focus {
      border-color: @green-color !important;
    }

    &.has-errors {
      border-color: @red-color !important;
    }

    &::placeholder {
      color: @secondary-font-color !important;
    }
  }

  .p-datepicker-panel {
    background-color: @white-base;
    box-shadow: @default-box-shadow;
    min-width: initial !important;
    border-radius: @border-radius-base;
    width: 250px;
    height: 250px;

    .p-datepicker-calendar-container {
      .p-datepicker-calendar {
        .p-datepicker-header {
          height: 38px;
          display: flex;
          align-items: center;
          padding: @spacing-x2;
          font-weight: @default-bold-value;
          border-top-left-radius: @border-radius-base;
          border-top-right-radius: @border-radius-base;
          border-bottom: 1px solid @secondary-border-color;
          background-color: @green-color;
          color: @white-base;

          .p-datepicker-prev-button,
          .p-datepicker-next-button {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: @border-radius-base;
          }

          .p-datepicker-title {
            .p-datepicker-select-month,
            .p-datepicker-select-year {
              transition: none;
              padding: 0 @spacing-x2;
              height: 24px;
              border-radius: @border-radius-base;
              text-transform: initial;
              font-size: @default-font-size;
            }
          }
        }

        .p-datepicker-day-view {
          font-size: @default-font-size;

          thead {
            tr {
              border-bottom: 1px solid @secondary-border-color;

              .p-datepicker-weekday {
                // font-weight: @default-bold-value;
              }
            }
          }

          tbody {
            .p-datepicker-day {
              width: 32px;
              height: 32px;
              border-radius: 100%;

              &:hover {
                background-color: @green-color-light;
                border: 1px solid @secondary-border-color;
              }

              &.p-datepicker-day-selected {
                background-color: @green-color-light;
                border: 1px solid @secondary-border-color;
              }

              &.p-disabled {
                opacity: 0.4;
                pointer-events: none;
              }
            }
          }
        }
      }
    }
    .p-datepicker-month-view,
    .p-datepicker-year-view {
      font-size: @default-font-size;

      .p-datepicker-month,
      .p-datepicker-year {
        width: 25%;
        height: 32px;
        border-radius: @border-radius-base;

        &:hover {
          background-color: @item-hover-background-color;
        }
      }
    }

    &.p-datepicker-timeonly {
      width: initial;
      height: initial;
      display: flex;
      justify-content: center;
      padding: @spacing-x2;

      .p-datepicker-time-picker {
        gap: @spacing-x2;

        .p-datepicker-hour-picker,
        .p-datepicker-minute-picker {
          display: flex;
          flex-direction: column;
          gap: @spacing-x2;

          .p-datepicker-increment-button {
            width: 24px;
            height: 24px;
            border-radius: @border-radius-base;
            color: @main-font-color;
          }
        }
      }
    }
  }

  .p-datepicker-clear-icon {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    width: 38px;
    height: 38px;
    color: @main-font-color;

    &:hover {
      color: @red-color;
    }
  }
}

// PRIMENG EDITOR

.p-editor-container {
  border: 1px solid @secondary-border-color;
  border-radius: @border-radius-base;

  .p-editor-toolbar {
    display: flex;
    padding: 0 !important;
    height: 38px;
    align-items: center;

    .ql-formats {
      margin: 0 !important;
      display: flex;
      padding: 0 @spacing-x2 !important;

      svg {
        stroke: @main-font-color;
      }

      button {
        border-radius: @border-radius-base;

        &:hover {
          svg {
            stroke: @green-color;
          }
        }

        &.ql-active {
          svg {
            stroke: @green-color;
          }
        }
      }

      .ql-picker {
        border-radius: @border-radius-base;
        color: @main-font-color !important;

        &:hover {
          outline: 1px solid @green-color;
        }

        &.ql-expanded {
          outline: 1px solid @green-color;

          .ql-picker-options {
            background-color: @white-base !important;
            box-shadow: @default-box-shadow !important;
            border-radius: @border-radius-base !important;
            margin-top: 1px;
          }
        }
      }

      &:not(:first-of-type) {
        border-left: 1px solid @secondary-border-color;
      }

      .ql-font,
      .ql-background,
      .ql-align,
      .ql-code-block {
        display: none;
      }

      &:last-of-type {
        display: none;
      }

      &::after {
        display: none !important;
      }
    }

    &::after {
      display: none !important;
    }
  }

  .p-editor-content {
    border-top: 1px solid @secondary-border-color !important;

    .ql-blank::before {
      font-style: normal !important;
      color: @secondary-font-color !important;
      font-family: @main-font-family !important;
      font-size: @default-font-size !important;
    }
  }

  &:hover {
    border-color: @secondary-font-color;
  }
}

.ql-editor li[data-list="bullet"] {
  &::before {
    display: none;
  }
}

// PRIMENG TOASTR

.p-toast {
  display: flex;
  flex-direction: column;
  gap: @spacing-x2;

  .p-toast-message {
    background-color: @white-base !important;
    box-shadow: @default-box-shadow !important;
    border-radius: @border-radius-base !important;
    border: 1px solid @secondary-border-color !important;
    padding: @spacing-x3 !important;
    width: 400px !important;
    margin: 0 !important;

    .p-toast-message-content {
      display: flex;
      align-items: center;
      gap: @spacing-x3;

      .p-toast-message-icon {
        color: @main-font-color !important;
        font-size: 18px !important;
      }

      .p-toast-message-text {
        .p-toast-detail {
          color: @main-font-color !important;
          font-weight: @default-bold-value !important;
        }
      }

      .p-toast-close-icon {
        color: @main-font-color !important;
        font-size: @default-font-size-small !important;
      }
    }

    &.p-toast-message-success {
      background-color: @green-color !important;

      .p-toast-message-content {
        .p-toast-message-icon {
          color: @white-base !important;
        }

        .p-toast-message-text {
          .p-toast-detail {
            color: @white-base !important;
          }
        }

        .p-toast-close-icon {
          color: @white-base !important;
        }
      }
    }

    &.p-toast-message-info {
      background-color: @blue-color !important;

      .p-toast-message-content {
        .p-toast-message-icon {
          color: @white-base !important;
        }

        .p-toast-message-text {
          .p-toast-detail {
            color: @white-base !important;
          }
        }

        .p-toast-close-icon {
          color: @white-base !important;
        }
      }
    }

    &.p-toast-message-warn {
      background-color: @orange-color !important;

      .p-toast-message-content {
        .p-toast-message-icon {
          color: @white-base !important;
        }

        .p-toast-message-text {
          .p-toast-detail {
            color: @white-base !important;
          }
        }

        .p-toast-close-icon {
          color: @white-base !important;
        }
      }
    }

    &.p-toast-message-error {
      background-color: @red-color !important;

      .p-toast-message-content {
        .p-toast-message-icon {
          color: @white-base !important;
        }

        .p-toast-message-text {
          .p-toast-detail {
            color: @white-base !important;
          }
        }

        .p-toast-close-icon {
          color: @white-base !important;
        }
      }
    }
  }
}
