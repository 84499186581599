@-webkit-keyframes circle-loader-load {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes circle-loader-load {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes straighten-up {
	0% {
		-webkit-transform: rotate(270deg) scale(0);
		transform: rotate(270deg) scale(0);
	}

	100% {
		-webkit-transform: rotate(360deg) scale(1);
		transform: rotate(360deg) scale(1);
	}
}

@keyframes straighten-up {
	0% {
		-webkit-transform: rotate(270deg) scale(0);
		transform: rotate(270deg) scale(0);
	}

	100% {
		-webkit-transform: rotate(360deg) scale(1);
		transform: rotate(360deg) scale(1);
	}
}